<template>
  <div class="resourceContent">
    <div class="articleMeauleft">
      <a-divider orientation="left">资料分类</a-divider>
      <a-tree
        :replaceFields="{
          title: 'name',
          key: 'id',
          children: 'children',
        }"
        :treeData="treeData"
        @select="onTypeSelect"
      />
    </div>
    <div class="articleMainRight">
      <div class="pxkeji-right-box">
        <a-form class="ant-advanced-search-form">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-item label="名称">
                <a-input placeholder="请输入" v-model="search.name" />
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item label="状态">
                <a-select v-model="search.status">
                  <a-select-option value="">全选</a-select-option>
                  <a-select-option value="0">未发布</a-select-option>
                  <a-select-option value="1">已发布</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4" :style="{ textAlign: 'left' }">
              <a-form-item>
                <a-button type="primary" @click="searchList">搜索</a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div>
          <pxkeji-btn
            name="添加"
            :roles="['资料管理-新增资料']"
            class="pxkeji_basic_btn"
            @click="showInfo(0)"
            type="primary"
          />
          <pxkeji-btn
            name="发布"
            :disabled="selectRows.length == 0"
            @click="batchPublish(1)"
            :roles="['资料管理-禁用启用']"
            class="pxkeji_basic_btn"
          />
          <pxkeji-btn
            name="下架"
            :disabled="selectRows.length == 0"
            @click="batchPublish(0)"
            :roles="['资料管理-禁用启用']"
            class="pxkeji_basic_btn"
          />
        </div>
      </div>
      <div>
        <a-table
          rowKey="id"
          size="small"
          bordered
          :columns="list.columns"
          :pagination="list.pagination"
          @change="onPageChange"
          :dataSource="list.datas"
          :loading="isloading"
          :rowSelection="{
            type: 'checkbox',
            onChange: onRowChange,
            columnWidth: 40,
            selectedRowKeys: selectRows,
          }"
          :locale="{ emptyText: '暂无数据' }"
          :scroll="{ y: list.tableHeight }"
        >
          <span slot="isGratis" slot-scope="isGratis">
            <a-tag v-if="isGratis == 0" color="red">否</a-tag>
            <a-tag v-else-if="isGratis == 1" color="green">是</a-tag>
          </span>
          <span slot="status" slot-scope="status">
            <a-tag v-if="status == 0" color="red">未发布</a-tag>
            <a-tag v-else-if="status == 1" color="green">已发布</a-tag>
            <a-tag v-else color="gray">已删除</a-tag>
          </span>
          <span slot="isRecommend" slot-scope="isRecommend">
            <a-switch
              checkedChildren="开"
              unCheckedChildren="关"
              :loading="switchLoading"
              v-model="isRecommend.isRecommend"
              @change="switchDisplay(isRecommend)"
            />
          </span>
          <span slot="action" slot-scope="action">
            <pxkeji-btn
              name="编辑"
              @click="showInfo(action.id)"
              size="small"
              type="primary"
              class="pxkeji_dro_btn"
              :roles="['资料管理-修改资料']"
            />
            <pxkeji-btn
              name="资料管理"
              @click="handleResourceData(action.id)"
              size="small"
              type="primary"
              class="pxkeji_dro_btn"
              :roles="['资料管理-修改资料']"
            />
            <a-popconfirm @confirm="delInfo(action.id)">
              <div slot="title">是否确认删除此资料？</div>
              <a-icon slot="icon" type="question-circle-o" />
              <pxkeji-btn
                name="删除"
                size="small"
                type="danger"
                class="pxkeji_dro_btn"
                :roles="['资料管理-删除资料']"
              />
            </a-popconfirm>
          </span>
        </a-table>
      </div>
    </div>
    <a-modal
      :title="info.title"
      v-model="info.visible"
      width="60%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <ResourceInfo @callbackMethod="save" :infos="info" />
    </a-modal>
    <a-modal
      title="资料管理"
      v-model="resourceData.visible"
      width="80%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <ResourceDatas @callbackMethod="save" :resourceId="resourceData.id" />
    </a-modal>
  </div>
</template>
<script>
import ResourceInfo from "./ResourceInfo.vue"; //编辑
import ResourceDatas from "./ResourceDatas.vue";

const columns = [
  {
    dataIndex: "name",
    align: "left",
    title: "资料名称",
    ellipsis: true,
  },

  {
    dataIndex: "typeName",
    align: "center",
    title: "资料分类",
    ellipsis: true,
  },
  {
    dataIndex: "isGratis",
    align: "center",
    title: "是否免费",
    width: 120,
    scopedSlots: { customRender: "isGratis" },
  },
  {
    dataIndex: "status",
    align: "center",
    title: "状态",
    width: 140,
    scopedSlots: { customRender: "status" },
  },
  {
    key: "isRecommend",
    align: "center",
    title: "是否推荐",
    width: 140,
    scopedSlots: { customRender: "isRecommend" },
  },
  {
    dataIndex: "createTime",
    key: "createTime",
    align: "center",
    width: 160,
    title: "发布日期",
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 240,
    scopedSlots: { customRender: "action" },
  },
];

let _this;
export default {
  name: "ResourceList",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 293,
      },
      search: {
        type: [],
        status: "",
        name: "",
      },
      selectRows: [], //选中项编号
      isloading: false,
      info: {
        title: "",
        visible: false,
        id: 0,
      },
      treeData: [],
      switchLoading: false, //开关等待
      resourceData: {
        visible: false,
        id: 0,
      },
    };
  },
  components: {
    ResourceInfo,
    ResourceDatas,
  },
  mounted() {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 292;
      _this.innerWidth = window.innerWidth;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.getTypeTree();
    _this.GetList();
  },
  methods: {
    getTypeTree() {
      _this.$api.resourceType
        .getTypeTree()
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.treeData = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onTypeSelect(val) {
      _this.search.typeId = val[0];
      _this.GetList();
    },
    switchDisplay(e) {
      _this.switchLoading = true;
      _this.$api.resource
        .updateRecommendById(
          e.id,
          _this.$commen.changeBooleanInt(e.isRecommend)
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.switchLoading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.clearRows();
      _this.$api.resource
        .getPageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search.typeId,
          _this.search.name,
          _this.search.status
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            res.data.records.forEach((value, index) => {
              res.data.records[index].isRecommend =
                _this.$commen.changeIntBoolean(value.recommend);
            });
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    onRowChange(selectedRowKeys) {
      _this.selectRows = selectedRowKeys;
    },
    clearRows() {
      //清空选中项
      _this.selectRows = [];
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    showInfo(id) {
      _this.info.title = id == 0 ? "添加" : "修改";
      _this.info.id = id;
      _this.info.typeId = _this.search.typeId;
      _this.info.visible = true;
    },
    //发布
    batchPublish(status) {
      let msg = "下架";
      if (status == 1) {
        msg = "发布";
      }
      _this.$confirm({
        title: "提示",
        content: `确定要批量${msg}选择的资料吗?`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.isDisable(_this.selectRows.join(), status);
        },
        onCancel() {},
      });
    },
    /**禁用启用的方法 */
    isDisable(ids, status) {
      _this.isloading = true;
      _this.$api.resource
        .updateStatusByIds(ids, status)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**删除 */
    delInfo(ids) {
      _this.isloading = true;
      _this.$api.resource
        .deleteById(ids)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**课时管理 */
    handleResourceData(id) {
      _this.resourceData.id = id;
      _this.resourceData.visible = true;
    },
    //子组件传值
    save() {
      _this.info.visible = false;
      _this.resourceData.visible = false;
      _this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.resourceContent:after {
  content: "";
  height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
.articleMeauleft {
  width: 20%;
  padding: 0 1%;
  min-height: 100px;
  background: #fff;
  float: left;
  margin-top: 10px;
}
.articleMainRight {
  width: 80%;
  float: right;
}
</style>

