<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div class>
      <a-form-model
        ref="resourceDataForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="资料名称" prop="title">
          <a-input placeholder="请输入" v-model="info.title"></a-input>
        </a-form-model-item>
        <a-form-model-item label="资料类型" prop="resourceType">
          <a-radio-group v-model="info.resourceType" @change="onTypeChange">
            <a-radio
              v-for="type in resourceTypeList"
              :key="type.type"
              :value="type.type"
            >
              {{ type.name }}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          label="文本"
          v-if="info.resourceType == 1"
          prop="content"
        >
          <vue-ueditor-wrap
            v-model="info.content"
            :config="myConfig"
          ></vue-ueditor-wrap>
        </a-form-model-item>
        <a-form-model-item label="文档" v-if="info.resourceType != 1">
          <a-upload
            accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,application/pdf"
            :action="$api.file.uploadFile"
            :fileList="fileList"
            :beforeUpload="beforeUpload"
            @change="handleUploadChange"
          >
            <a-button type="primary"> <a-icon type="upload" /> 上传 </a-button>
          </a-upload>
          <span class="tishi">
            {{
              `注：支持的文件格式为：
              ${info.resourceType == 2 ? "doc、docx" : ""}
              ${info.resourceType == 3 ? "xls、xlsx" : ""}
              ${info.resourceType == 4 ? "ppt、pptx" : ""}
              ${info.resourceType == 5 ? "pdf" : ""}
              ，文件大小在20M以下。`
            }}
          </span>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
import VueUeditorWrap from "vue-ueditor-wrap";
let _this;
export default {
  name: "ResourceDataInfo",
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      info: {
        resourceType: 1,
        content: "",
      },
      rules: {
        title: [{ required: true, message: "请输入资料名称", trigger: "blur" }],
        resourceType: [
          { required: true, message: "请选择资料类型", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入资料内容", trigger: "blur" },
        ],
      },
      fileList: [],
      previewVisible: false,
      resourceTypeList: [],
      myConfig: {
        autoHeightEnabled: false,
        initialFrameHeight: 300,
        wordCount: true,
        maximumWords: 100000,
        initialFrameWidth: "100%",
        serverUrl: this.$api.file.uploadUpFile,
        UEDITOR_HOME_URL: "/UEditor/",
        toolbars: this.$commen.editorConfig.toolbars,
      },
    };
  },
  components: {
    VueUeditorWrap,
  },
  mounted() {
    _this = this;
    _this.getResourceTypeList();
    if (_this.infos.id != 0) {
      _this.getInfo();
    }
  },
  methods: {
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    async getResourceTypeList() {
      let res = await _this.$api.resourceChapterData.getFileType();
      if (res.errorCode == _this.$msg.responseCode.success) {
        res.data.forEach(function (item) {
          if (item.type > 1) {
            _this.resourceTypeList.push(item);
          }
        });
      } else {
        _this.$message.error(res.errorMsg);
      }
    },
    onTypeChange() {
      _this.fileList = [];
      _this.info.content = "";
      _this.info.fileLength = 0;
      _this.info.fileName = "";
    },
    /**获取详情 */
    getInfo() {
      _this.isloading = true;
      _this.$api.resourceChapterData
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info = res.data;
            _this.info.resourceType = Number(res.data.resourceType);
            if (res.data.resourceType != 0 && res.data.content) {
              _this.fileList.push({
                uid: 1,
                name: res.data.fileName,
                status: "done",
              });
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.resourceDataForm.validate((valid) => {
        if (valid) {
          let formData = {
            ..._this.info,
            resourceId: _this.infos.resourceId,
            chapterId: _this.infos.chapterId,
          };
          if (_this.infos.id != 0) {
            formData.id = _this.infos.id;
          }
          //添加
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          _this.$api.resourceChapterData
            .saveOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    beforeUpload(file) {
      let fileTypes = [
        {
          resourceType: 2,
          types: ["doc", "docx"],
        },
        {
          resourceType: 3,
          types: ["xls", "xlsx"],
        },
        {
          resourceType: 4,
          types: ["ppt", "pptx"],
        },
        {
          resourceType: 5,
          types: ["pdf"],
        },
      ];
      let isType = false;
      let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      let types = fileTypes.filter(
        (item) => item.resourceType == _this.info.resourceType
      )[0].types;
      if (types.indexOf(fileType) != -1) {
        isType = true;
      } else {
        isType = false;
        _this.$message.error("只能上传指定的文件格式");
      }
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        _this.$message.error("文件不能超过20MB");
      }
      return isType && isLt20M;
    },
    handleUploadChange(fileInfo) {
      if (fileInfo.file.status) {
        let fileList = [...fileInfo.fileList];
        _this.fileList = fileList.slice(-1);
        if (fileInfo.file.status == "done") {
          if (
            fileInfo.file.response.errorCode != _this.$msg.responseCode.success
          ) {
            _this.$message.error("上传失败");
          } else {
            _this.info.content = fileInfo.file.response.data.path;
            _this.info.fileLength = fileInfo.file.response.data.size;
            _this.info.fileName = fileInfo.file.response.data.name;
          }
        } else if (fileInfo.file.status == "error") {
          _this.$message.error("上传失败");
        } else if (fileInfo.file.status == "removed") {
          _this.info.content = "";
          _this.info.fileLength = 0;
          _this.info.fileName = "";
        }
      }
    },
  },
};
</script>
<style>
</style>