<template>
  <div>
    <div class="pxkeji-right-box">
      <pxkeji-btn
        name="添加章节"
        class="pxkeji_basic_btn"
        @click="addChapter(0, 0)"
        type="primary"
        v-if="showChapterAdd"
        :roles="['']"
      />
      <pxkeji-btn
        name="添加资料"
        class="pxkeji_basic_btn"
        @click="addData(0, 0)"
        type="primary"
        v-if="showDataAdd"
        :roles="['']"
      />
      <a-table
        rowKey="id"
        childrenColumnName="dataList"
        expandRowByClick
        :expandedRowKeys="expandedRows"
        :columns="list.columns"
        :dataSource="list.datas"
        :pagination="false"
        :loading="isloading"
        :locale="{ emptyText: '暂无数据' }"
      >
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="编辑章节"
            size="small"
            @click="addChapter(action.id, action.parentId)"
            v-if="action.dataType == '章节'"
          />
          <pxkeji-btn
            name="添加资料"
            size="small"
            @click="addData(0, action.id)"
            type="primary"
            v-if="action.dataType == '章节'"
          />
          <pxkeji-btn
            name="编辑资料"
            size="small"
            @click="addData(action.id, action.chapterId)"
            type="primary"
            v-if="action.dataType == '文件'"
          />
          <a-popconfirm @confirm="deleteChapter(action.id)">
            <div slot="title">是否确认删除此章节？</div>
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除章节"
              size="small"
              type="danger"
              v-if="action.dataType == '章节'"
            />
          </a-popconfirm>
          <a-popconfirm @confirm="deleteData(action.id)">
            <div slot="title">是否确认删除此资料文件？</div>
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除资料"
              size="small"
              type="danger"
              v-if="action.dataType == '文件'"
            />
          </a-popconfirm>
          <!-- <pxkeji-btn
            name="上移"
            size="small"
            @click="handleMove(action.id, 1, action.dataType == '文件' ? 1 : 0)"
            type="default"
          />
          <pxkeji-btn
            name="下移"
            size="small"
            @click="handleMove(action.id, 0, action.dataType == '文件' ? 1 : 0)"
            type="default"
          /> -->
        </span>
      </a-table>
    </div>

    <a-modal
      :title="chapterInfo.title"
      v-model="chapterInfo.visible"
      width="700px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <ResourceChapterInfo @callbackMethod="saveBack" :infos="chapterInfo" />
    </a-modal>
    <a-modal
      :title="dataInfo.title"
      v-model="dataInfo.visible"
      width="60%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <ResourceDataInfo @callbackMethod="saveBack" :infos="dataInfo" />
    </a-modal>
  </div>
</template>
<script>
import ResourceChapterInfo from "./ResourceChapterInfo.vue"; //章节
import ResourceDataInfo from "./ResourceDataInfo.vue"; //文件

var columns = [
  {
    dataIndex: "title",
    align: "left",
    title: "名称",
  },
  {
    dataIndex: "dataType",
    align: "left",
    title: "类型",
  },
  {
    key: "action",
    align: "right",
    title: "操作",
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "ResourceDatas",
  props: {
    resourceId: String,
  },
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
      },
      isloading: false,
      showChapterAdd: false,
      showDataAdd: true,
      expandedRows: [],
      chapterInfo: {
        visible: false,
        title: "",
        resourceId: 0,
        id: 0,
        parentId: 0,
      },
      dataInfo: {
        visible: false,
        title: "",
        resourceId: 0,
        chapterId: 0,
        id: 0,
      },
    };
  },
  components: {
    ResourceChapterInfo,
    ResourceDataInfo,
  },
  mounted() {
    _this = this;
    console.log(111111)
    _this.getResourceDetail();
  },
  methods: {
    getResourceDetail() {
      _this.isloading = true;
      _this.list.datas = [];
      _this.$api.resource
        .getResourceDetail(_this.resourceId)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            if (res.data.chapterList) {
              _this.showDataAdd = false;
              _this.list.datas = res.data.chapterList;
              _this.expandedRows = res.data.chapterList.map((item) => item.id);
              _this.list.datas.forEach((item) => {
                item.dataType = "章节";
                if (item.dataList) {
                  item.dataList.forEach((data) => (data.dataType = "文件"));
                }
              });
            } else if (res.data.dataList) {
              _this.showChapterAdd = false;
              _this.showDataAdd = false;
              _this.list.datas = res.data.dataList;
              _this.list.datas.forEach((item) => (item.dataType = "文件"));
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    deleteChapter(id) {
      _this.isloading = true;
      _this.$api.resource
        .deleteChapter(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.getResourceDetail();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    deleteData(id) {
      _this.isloading = true;
      _this.$api.resourceChapterData
        .deleteById(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.getResourceDetail();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**添加章节 */
    addChapter(id, parentId) {
      _this.chapterInfo.title = id == 0 ? "添加章节" : "编辑章节";
      _this.chapterInfo.resourceId = _this.resourceId;
      _this.chapterInfo.id = id;
      _this.chapterInfo.parentId = parentId;
      _this.chapterInfo.visible = true;
    },
    /**添加资料文件 **/
    addData(id, chapterId) {
      _this.dataInfo.title = id == 0 ? "添加资料文件" : "编辑资料文件";
      _this.dataInfo.resourceId = _this.resourceId;
      _this.dataInfo.chapterId = chapterId;
      _this.dataInfo.id = id;
      _this.dataInfo.visible = true;
    },
    saveBack() {
      _this.chapterInfo.visible = false;
      _this.dataInfo.visible = false;
      _this.getResourceDetail();
    },
    handleMove(id, isUp, type) {
      _this.isloading = true;
      _this.$api.resource
        .sortData(id, isUp, type)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.getResourceDetail();
          } else {
            _this.$message.error(res.errorMsg);
            _this.isloading = false;
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>

