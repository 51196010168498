<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="resourceForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="资料分类" prop="typeIds">
          <a-cascader
            placeholder="请选择"
            :options="treeData"
            changeOnSelect
            allowClear
            v-model="info.typeIds"
            :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
          />
        </a-form-model-item>
        <a-form-model-item label="资料名称" prop="name">
          <a-input placeholder="请输入" v-model="info.name"></a-input>
        </a-form-model-item>
        <a-form-model-item
          v-for="(item, index) in groupList"
          :key="index"
          :label="item.name"
        >
          <a-cascader
            :field-names="{
              label: 'itemName',
              value: 'id',
              children: 'children',
            }"
            :options="item.dictionaryItemList"
            placeholder="请选择"
            v-model="item.defaultValue"
            @change="onChange"
          />
          <!-- <a-select v-model="item.defaultValue" placeholder="请选择">
            <template v-for="tag in item.dictionaryItemList">
              <a-select-option :value="tag.id" :key="tag.itemName">
                {{ tag.itemName }}
              </a-select-option>
            </template>
          </a-select> -->
        </a-form-model-item>
        <a-form-model-item label="资料介绍" prop="introduce">
          <a-textarea placeholder="请输入" v-model="info.introduce" :rows="4" />
        </a-form-model-item>
        <a-form-model-item label="收费方式">
          <a-radio-group v-model="info.isGratis">
            <a-radio :value="item.id"  v-for="(item, index) in chargeTypeList"
          :key="index"> {{item.name}} </a-radio>
          </a-radio-group>
          <!-- <a-switch
            checked-children="是"
            un-checked-children="否"
            v-model="info.isGratis"
          ></a-switch> -->
        </a-form-model-item>
        <a-form-model-item
          v-if="!info.isGratis"
          label="原价"
          prop="originalPrice"
        >
          <a-input placeholder="请输入" v-model="info.originalPrice"></a-input>
        </a-form-model-item>
        <a-form-model-item
          v-if="!info.isGratis"
          label="现价"
          prop="presentPrice"
        >
          <a-input placeholder="请输入" v-model="info.presentPrice"></a-input>
        </a-form-model-item>
        <a-form-model-item label="展示图">
          <a-upload
            accept="image/png,image/jpeg"
            listType="picture-card"
            :action="$api.file.uploadFile"
            :fileList="fileList"
            :beforeUpload="beforeUpload"
            @preview="handlePreview"
            @change="handleFileChange"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img style="width: 100%" :src="info.picUrl" />
          </a-modal>
          建议尺寸:260像素*160像素
        </a-form-model-item>
         <a-form-model-item label="发布时间">
              <a-date-picker v-model="info.createTime" :showTime="true" @change="timeChange"/>
            </a-form-model-item>
        <a-form-model-item label="状态">
          <a-switch
            checked-children="发布"
            un-checked-children="草稿"
            v-model="info.status"
          ></a-switch>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
let _this;
export default {
  name: "ResourceInfo",
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let validateNum = (rule, value, callback) => {
      if (!/^[0-9]+(\.[0-9]{0,2})?$/.test(value)) {
        callback(new Error("请输入两位以内小数的数字"));
      } else {
        if (!this.info.isGratis && value == 0) {
          callback(new Error("收费状态下价格不能为0"));
        } else {
          callback();
        }
      }
    };
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      info: {
        status: false,
        typeIds: [],
        isGratis: 1,
        originalPrice: 0,
        presentPrice: 0,
        createTime:null,
        resourceGroupItemList: [],
      },
      chargeTypeList:[
        {name:'免费',id:1},
        {name:'积分兑换',id:2},
        {name:'收费',id:0},
        ],
      rules: {
        typeIds: [
          {
            type: "array",
            required: true,
            message: "请选择分类",
            trigger: "change",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入资料名称",
            trigger: "blur",
          },
        ],
        introduce: [
          {
            required: true,
            message: "请输入资料介绍",
            trigger: "blur",
          },
        ],
        originalPrice: [{ validator: validateNum, trigger: "change" }],
        presentPrice: [{ validator: validateNum, trigger: "change" }],
      },
      treeData: [],
      groupList: [],
      fileList: [],
      previewVisible: false,
    };
  },
  mounted() {
    _this = this;
    _this.getTypeTree();
    _this.getResourceGroupList(); // 课程属性
    if (_this.infos.id != 0) {
      _this.getInfo();
    } else if (_this.infos.typeId) {
      _this.getParentChain(_this.infos.typeId);
    }
  },
  methods: {
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    timeChange(date, dateString) {
      
      _this.info.createTime = dateString;
    },
    //分类
    async getTypeTree() {
      let res = await _this.$api.resourceType.getUseTree();
      if (res.errorCode == _this.$msg.responseCode.success) {
        _this.treeData = res.data;
      } else {
        _this.$message.error(res.errorMsg);
      }
    },
    //属性
    async getResourceGroupList() {
      let res = await _this.$api.resource.getResourceGroupList();
      if (res.errorCode == _this.$msg.responseCode.success) {
        _this.groupList = res.data;
      } else {
        _this.$message.error(res.errorMsg);
      }
    },
    /**回显栏目 */
    getParentChain(id) {
      _this.$api.resourceType
        .getParentIdList(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info.typeIds = res.data;
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {});
    },
    //详情
    getInfo() {
      _this.isloading = true;
      _this.$api.resource
        .getById(_this.infos.id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info = {
              ...res.data,
              typeIds: [],
              status: _this.$commen.changeIntBoolean(res.data.status),
            };
            _this.getParentChain(res.data.typeId);

            let resourceItems = _this.info.resourceGroupItemList;
            if (resourceItems && resourceItems.length > 0) {
              _this.groupList.forEach((item) => {
                let result = resourceItems.filter(
                  (group) => group.groupId == item.groupId
                );
                if (result && result.length > 0) {
                  item.defaultValue = result[0].itemIdList;
                }
              });
            } else {
              _this.info.resourceGroupItemList = [];
            }

            if (res.data.picUrl) {
              _this.fileList.push({
                status: "done",
                url: res.data.picUrl,
                name: res.data.picUrl.substring(
                  res.data.picUrl.lastIndexOf("/") + 1
                ),
                response: {
                  data: { name: res.data.picUrl, path: res.data.picUrl },
                },
                uid: 1,
                thumbUrl: res.data.picUrl,
              });
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.resourceForm.validate((valid) => {
        if (valid) {
          let formData = {
            ..._this.info,
            status: _this.$commen.changeBooleanInt(_this.info.status),
          };
          formData.typeId = _this.info.typeIds[_this.info.typeIds.length - 1];
          delete formData.typeIds;
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          _this.groupList.forEach((item) => {
            if (item.defaultValue) {
              let exit = false;
              let groupItem = {
                groupId: item.groupId,
                itemId: item.defaultValue[item.defaultValue.length - 1],
              };
              if (_this.id != 0) {
                groupItem.resourceId = _this.id;
              }
              for (let i = 0; i < formData.resourceGroupItemList.length; i++) {
                if (formData.resourceGroupItemList[i].groupId == item.groupId) {
                  formData.resourceGroupItemList.splice(i, 1, groupItem);
                  exit = true;
                  break;
                }
              }
              if (!exit) {
                formData.resourceGroupItemList.push(groupItem);
              }
            }
          });
          if (_this.infos.id != 0) {
            formData.id = _this.infos.id;
          }
          //新增
          _this.$api.resource
            .saveOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      _this.previewVisible = false;
    },
    handlePreview() {
      _this.previewVisible = true;
    },
    beforeUpload(file) {
      let isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传jpg/png格式的文件!");
      }
      let isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        _this.$message.error("文件不能超过2MB!");
      }
      return isJPGPNG && isLt2M;
    },
    handleFileChange(fileInfo) {
      _this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "done") {
        if (
          fileInfo.file.response.errorCode != _this.$msg.responseCode.success
        ) {
          _this.$message.error("上传失败");
        } else {
          _this.info.picUrl = fileInfo.file.response.data.path;
        }
      } else if (fileInfo.file.status == "error") {
        _this.$message.error("上传失败");
      } else if (fileInfo.file.status == "removed") {
        _this.info.picUrl = "";
      }
    },
    onChange() {
      this.$forceUpdate();
    },
  },
};
</script>
<style>
</style>